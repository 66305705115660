import React from 'react';
import './index.css';

const ua = navigator.userAgent.toLowerCase();
const testUa = regexp => regexp.test(ua);
class Foot extends React.Component {
    getSystem() {
        
        let system='';
        if (testUa(/windows|win32|win64|wow32|wow64/g)) {
            system = "windows"; // windows系统
        } else if (testUa(/macintosh|macintel/g)) {
            system = "macos"; // macos系统
        } else if (testUa(/x11/g)) {
            system = "linux"; // linux系统
        } else if (testUa(/android|adr/g)) {
            system = "android"; // android系统
        } else if (testUa(/ios|iphone|ipad|ipod|iwatch/g)) {
            system = "ios"; // ios系统
        }
        return system;
    }
    getPlatform() {
        let system = this.getSystem();
        let platform = "unknow";
        if (system === "windows" || system === "macos" || system === "linux") {
            platform = "desktop"; // 桌面端
        } else if (system === "android" || system === "ios" || testUa(/mobile/g)) {
            platform = "mobile"; // 移动端
        }
        return platform;
    }
    render(){
        return(
            <div id="bottom_layer" className={this.getPlatform()==='mobile'?'display-none bottom-layer':'bottom-layer'}>
            <div id="bottom_layer_right" className="bottom-layer-right">
                <span className="lh">@2020&nbsp;vaccine.pub&nbsp;</span>
                <span className="lh"><a href="http://www.beian.miit.gov.cn/" target="view_window">京ICP备20012461号</a></span>
            </div>
            </div>
        )
    }
}

export default Foot