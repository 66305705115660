import React from 'react';
import ReactDOM from 'react-dom';
import FetchHttpClient, { json } from 'fetch-http-client';
import './index.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Mycalendar from 'react-calendar-zw';

import Result from './result'
import config from './config'
import Foot from './foot'

class Vaccine extends React.Component {
  
  constructor(props){
    super(props)
    this.state = {
      date: '',
      page_index: 1,
      brdate: '',
      calendarDisplay: false
    }
    // this.getList()
  }
  getList() {
    const client = new FetchHttpClient(config.serverDomain);
    client.addMiddleware(json());
    client.get('/mini/getlast').then(response => {
      console.log(response.jsonData);
      this.setState({
        date: '最后更新时间：'+response.jsonData.data
      });
    }
      
    );
 
  }
  nextPage(){
    this.setState({
      page_index: 2
    })
  }
  subjectData(){
    console.log('')
  }
  handleChange(event) {
    console.log(event.target)
  }
  handleSubmit(event) {

    // event.preventDefault();
    // history.push('/result');
  }
  callCalendar(){
    this.setState({
      calendarDisplay: true
    })
  }
  inputCalendar(value){
    console.log(value);
    this.setState({
      brdate: value,
      calendarDisplay: false
    })
  }
  datePickCallBack=(date) => {
    this.setState({
      brdate: date,
      calendarDisplay: false,
    });
  }
  render() {
    let mapVaccine = config.mapVaccine;
    return (
      <div>
        <span className={this.state.page_index===1?'':'display-none'}>
        <p align="center">国家免疫规划疫苗儿童免疫程序表（2016年版）</p>
          <table border="1">
            <thead>
              <tr><th className={'keyDesc'}>名称</th><th>0月</th><th>1月</th><th>2月</th><th>3月</th><th>4月</th><th>5月</th><th>6月</th><th>8月</th><th>9月</th><th>12月</th><th>18月</th><th>2岁</th><th>3岁</th><th>4岁</th><th>5岁</th><th>6岁</th></tr>
            </thead>
            <tbody>
            {Object.keys(mapVaccine).map((key) =>
              <tr key={mapVaccine[key].keyName} className={mapVaccine[key].state?'':'display-none'}><td>{mapVaccine[key].des}</td>
                {mapVaccine[key].accinationMonthAge.map((value, index) =>
                <td key={index}>{value>0?'💉':''}</td>
                )}
              </tr>
            )}
            </tbody>
          </table>
          <button onClick={this.nextPage.bind(this)}>选择二类疫苗</button>
        </span>
        <span className={this.state.page_index===2?'':'display-none'}>
        <form onSubmit={this.handleSubmit} action="/result">
        <table >
          <thead>
            <tr><th>选择疫苗</th><th>疫苗说明</th></tr>
          </thead>
          <tbody>
            <tr><td><input type="checkbox" name="level2vaccine" value="DTaP-IPV/Hib" />五联</td><td>吸附无细胞百白破灭活脊髓灰质炎和b型流感嗜血杆菌（结合）联合疫苗,可以同时联合5种疫苗，能从之前的12针缩减到4针。</td></tr>
            <tr><td><input type="checkbox" name="level2vaccine" value="PCV13" />13价肺炎</td><td>13价肺炎球菌结合疫苗</td></tr>
            <tr><td><input type="checkbox" name="level2vaccine" value="LLR" />轮状病毒</td><td>口服轮状病毒疫苗用轮状病毒减毒株[建议每年一剂直至三岁，4~6岁再接种一次即可]</td></tr>
          </tbody>
          <tfoot>
            <tr><td>新生儿出生日期：</td><td>
        <div className="calenderBox">
          <input
            name="brdate"
            type="text"
            value={this.state.brdate}
            placeholder="请选择日期"
            onClick={
                  () => {
                    this.setState({
                      calendarDisplay: true,
                    });
                  }
                }
            onChange={() => {}}
          />
          <div className="calenderBox"><Mycalendar isShow={this.state.calendarDisplay} datePickCallBack={this.datePickCallBack}></Mycalendar></div>
        </div></td></tr>
        <tr><td><input type="submit" value='提交'></input></td><td></td></tr>
          </tfoot>
        </table>
        
        
        
        </form>
        </span>


        
        
        <div>{this.state.date}</div>

      </div>
      
      
    )
  }
}


class Root extends React.Component {
  render() {
    return(
      <div>
        <Router>
          <Switch>
            <Route path="/result"><Result /></Route>
            <Route path="/"><Vaccine /></Route>
          </Switch>

        </Router>
        <Foot />
      </div>
      
      
    )
  }
}

// ========================================

ReactDOM.render(
  <Root />,
  document.getElementById('root')
);
