let globalData = {
    serverDomain: "http://api.vaccine.pub",
    mapVaccine: {
        'HepB':{keyName: 'HepB',des: '乙肝疫苗',accinationMonthAge: [1,2,0,0,0,0,3,0,0,0,0,0,0,0,0,0] ,state: true},
        'BCG':{keyName: 'BCG',des: '卡介疫苗',accinationMonthAge: [1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0] ,state: true},
        'IPV':{keyName: 'IPV',des: '脊灰灭活疫苗',accinationMonthAge: [0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0], state: true},
        'OPV':{keyName: 'OPV',des: '脊灰减毒活疫苗',accinationMonthAge: [0,0,0,1,2,0,0,0,0,0,0,0,0,3,0,0],state: true},
        'DTaP':{keyName: 'DTaP',des: '百白破疫苗',accinationMonthAge: [0,0,0,1,2,3,0,0,0,0,4,0,0,0,0,0],state: true},
        'DT':{keyName: 'DT',des: '白破疫苗',accinationMonthAge: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1],state: true},
        'MR':{keyName: 'MR',des: '麻风疫苗',accinationMonthAge: [0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0],state: true},
        'MMR':{keyName: 'MMR',des: '麻腮风疫苗',accinationMonthAge: [0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0],state: true},
        'JE':{keyName: 'JE-L',des: '乙脑减毒活疫苗',accinationMonthAge: [0,0,0,0,0,0,0,1,0,0,0,2,0,0,0,0],state: true},
        'MPSV-A':{keyName: 'MPSV-A',des: 'A群流脑多糖疫苗',accinationMonthAge: [0,0,0,0,0,0,1,0,2,0,0,0,0,0,0,0],state: true},
        'MPSV-AC':{keyName: 'MPSV-AC',des: 'A群C群流脑多糖疫苗',accinationMonthAge: [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,2],state: true},
        'HepA-I':{keyName: 'HepA-I',des: '甲肝灭活疫苗',accinationMonthAge: [0,0,0,0,0,0,0,0,0,0,1,2,0,0,0,0],state: true},
        'DTaP-IPV/Hib':{keyName: 'DTaP-IPV/Hib',des: '五联疫苗',accinationMonthAge: [0,0,1,2,3,0,0,0,0,0,4,0,0,0,0,0],state: false},
        'PCV13':{keyName: 'PCV13',des: '13价肺炎疫苗',accinationMonthAge: [0,0,1,0,2,0,3,0,0,4,0,0,0,0,0,0],state: false},
        'LLR':{keyName: 'LLR',des: '轮状病毒疫苗',accinationMonthAge: [0,0,0,0,0,0,1,0,0,0,2,3,4,0,5,0],state: false}
    }
}

export default globalData;