import React from 'react';
import './index.css';
import config from './config'

class Result extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            brdate: this.getQueryString('brdate'),
            level2vaccine: this.getQueryString('level2vaccine'),
            myVaccineResult: []
        }
        this.dataProcessing();
    }
    dataProcessing() {
        this.state.level2vaccine.forEach((l2item) => {
            switch(l2item){
                case 'DTaP-IPV/Hib':
                    config.mapVaccine['IPV']['state'] = false;
                    config.mapVaccine['OPV']['state'] = false;
                    config.mapVaccine['DTaP']['state'] = false;
                    config.mapVaccine['DTaP-IPV/Hib']['state'] = true;
                    break;
                case 'PCV13':
                    config.mapVaccine['PCV13']['state'] = true;
                    break;
                case 'LLR':
                    config.mapVaccine['LLR']['state'] = true;
                    break;
                default:
                    console.log(l2item);
                    break;
            }
        });
    }
    getQueryString(name) {
        // level2vaccine=DTaP-IPV%2FHib&level2vaccine=PCV13&brdate=2019-6-26
        let return_value = []
        var reg = new RegExp(name + "=([^&]*)(&|$)",'g');
        let url_parm = window.location.search.substr(1)
        var r = url_parm.match(reg);
        if(r == null){
            return null;
        }
        r.forEach((item) => {
            return_value.push(item.replace('&','').replace('%2F','/').split('=')[1])
        })
		return return_value
    }
    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      }
    render() {
        let mapVaccine = config.mapVaccine;
        let nowTime = new Date();
        let brTime = new Date(this.state.brdate);
        let monthage = [[0,1],[1,30],[2,30],[3,30],[4,30],[5,30],[6,30],[8,30],[9,30],[12,60],[18,60],[24,365],[36,365],[48,365],[60,365],[72,365]];
        return(
            
            <div>
                <div>出生日期：{this.state.brdate} ,建议横屏查看。<a href="https://github.com/guohai163/vaccine"  target="view_window">view source</a> </div>
                <table border="1">
                    <thead>
                    <tr><th className={'keyDesc'}>名称</th><th>0月</th><th>1月</th><th>2月</th><th>3月</th><th>4月</th><th>5月</th><th>6月</th><th>8月</th><th>9月</th><th>12月</th><th>18月</th><th>2岁</th><th>3岁</th><th>4岁</th><th>5岁</th><th>6岁</th></tr>
                    </thead>
                    <tbody>
                    {Object.keys(mapVaccine).map((key) =>
                    
                    <tr key={mapVaccine[key].keyName} className={mapVaccine[key].state?'':'display-none'}><td>{mapVaccine[key].des}</td>
                        {mapVaccine[key].accinationMonthAge.map((value, index) =>
                        <td key={index} className={nowTime<this.addDays(brTime,monthage[index][0]* 30)?'day_future':nowTime<=this.addDays(brTime,monthage[index][0]*30+monthage[index][1])?'day_current':'day_fore'}>{value>0?'💉':''}</td>
                        )}
                    </tr>
                    )}
                    </tbody>
                </table>
                <table className="tbDesColor">
                <tbody>
                    <tr>
                        <td className='desColor day_future'></td><td>未到日期</td>
                        <td className='desColor day_current'></td><td>当前日期</td>
                        <td className='desColor day_fore'></td><td>已过日期</td>
                    </tr>
                </tbody>
                </table>
            </div>
        )
    }
}

export default Result